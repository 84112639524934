<template>
  <div>
    <h1>This is an 资讯 page</h1>
  </div>
</template>

<script>
export default {
  name: 'News'
}
</script>

<style scoped>

</style>